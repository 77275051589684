import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/desktop/_templates/chapter_4/sub_12/slide2';

const Slide = () => {
  const query = useStaticQuery(graphql`
  query {
      hoverDot: mdx(
      frontmatter: {
        language: { eq: "PL" }
        title: { eq: "help-from-ukraine-dots" }
      }
    ) {
      exports {
        hoverInfo {
          positionLeft
          positionTop
          insideText
          identifier
          main
        }
      }
    },
    mdx(frontmatter: {language: {eq: "PL"}, title: {eq: "slide-4-12-2"}}) {
        body
      },
    modal41221:  mdx(frontmatter: {language: {eq: "PL"}, title: {eq: "ukrainians-modals"}, selector: {eq: "modal41221"}}) {
      body
    },
    modal41222:  mdx(frontmatter: {language: {eq: "PL"}, title: {eq: "ukrainians-modals"}, selector: {eq: "modal41222"}}) {
      body
    },
    modal41223:  mdx(frontmatter: {language: {eq: "PL"}, title: {eq: "ukrainians-modals"}, selector: {eq: "modal41223"}}) {
      body
    },
    modal41224:  mdx(frontmatter: {language: {eq: "PL"}, title: {eq: "ukrainians-modals"}, selector: {eq: "modal41224"}}) {
      body
    },
    modal41225:  mdx(frontmatter: {language: {eq: "PL"}, title: {eq: "ukrainians-modals"}, selector: {eq: "modal41225"}}) {
      body
    },
    modal41226:  mdx(frontmatter: {language: {eq: "PL"}, title: {eq: "ukrainians-modals"}, selector: {eq: "modal41226"}}) {
      body
    },
    modal41227:  mdx(frontmatter: {language: {eq: "PL"}, title: {eq: "ukrainians-modals"}, selector: {eq: "modal41227"}}) {
      body
    },
    modal41228:  mdx(frontmatter: {language: {eq: "PL"}, title: {eq: "ukrainians-modals"}, selector: {eq: "modal41228"}}) {
      body
    },
    caption:   mdx(frontmatter: {language: {eq: "PL"}, title: {eq: "slide-4-6-1"}}) {
      body
  }
  }
  `);
  return (
    <Template query={query} />
  );
};


export default Slide;
