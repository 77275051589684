import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/mobile/_templates/chapter_4/sub_12/slide6';

const Slide = () => {
  const query = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "chapter_4/sub_12/mobile/4-12-6-bg.jpg" }) {
        childImageSharp {
          fixed(width: 1920) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      modal41261:  mdx(frontmatter: {language: {eq: "PL"}, title: {eq: "ru-ko-bi-modals"}, selector: {eq: "modal41261"}}) {
        body
        frontmatter {
          name
        }
      },
      modal41262:  mdx(frontmatter: {language: {eq: "PL"}, title: {eq: "ru-ko-bi-modals"}, selector: {eq: "modal41262"}}) {
        body
        frontmatter {
          name
        }
      },
      modal41263:  mdx(frontmatter: {language: {eq: "PL"}, title: {eq: "ru-ko-bi-modals"}, selector: {eq: "modal41263"}}) {
        body
        frontmatter {
          name
        }
      },
    }
  `);
  return <Template query={query} />;
};

export default Slide;
