import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/desktop/_templates/chapter_4/sub_12/slide6';

const Slide = () => {
  const query = useStaticQuery(graphql`
  query {
    leftColumn: mdx(frontmatter: {language: {eq: "PL"}, title: {eq: "slide-4-12-6"}}) {
        body
      },
      hoverDot: mdx(
        frontmatter: {
          language: { eq: "PL" }
          title: { eq: "ru-ko-bi-dots" }
        }
      ) {
        exports {
          hoverInfo {
            positionLeft
            positionTop
            identifier
          }
        }
      },
      modal41261:  mdx(frontmatter: {language: {eq: "PL"}, title: {eq: "ru-ko-bi-modals"}, selector: {eq: "modal41261"}}) {
        body
      },
      modal41262:  mdx(frontmatter: {language: {eq: "PL"}, title: {eq: "ru-ko-bi-modals"}, selector: {eq: "modal41262"}}) {
        body
      },
      modal41263:  mdx(frontmatter: {language: {eq: "PL"}, title: {eq: "ru-ko-bi-modals"}, selector: {eq: "modal41263"}}) {
        body
      },
  }
  `);
  return (
    <Template query={query} />
  );
};


export default Slide;
