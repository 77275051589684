import React from 'react';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider.jsx';
import Slide1 from 'src/slides/desktop/pl/chapter_4/sub_12/slide1';
import Slide2 from 'src/slides/desktop/pl/chapter_4/sub_12/slide2';
import Slide3 from 'src/slides/desktop/pl/chapter_4/sub_12/slide3';
import Slide4 from 'src/slides/desktop/pl/chapter_4/sub_12/slide4';
import Slide5 from 'src/slides/desktop/pl/chapter_4/sub_12/slide5';
import Slide6 from 'src/slides/desktop/pl/chapter_4/sub_12/slide6';
import SEO from 'src/components/_shared/seo';
import WrapperMobile from 'src/slides/mobile/pl/chapter_4/sub_12/wrapperMobile';

import { isMobile } from 'react-device-detect';

const allSlides = [<Slide1 />, <Slide2 />, <Slide3 />, <Slide4 />, <Slide5 />, <Slide6 />];

const exceptions = [];

const Desktop = () => (
  <>
    <SEO title="Kto był sojusznikiem Polski w wojnie polsko-bolszewickiej | Bitwa Warszawska" lang="pl" description="Ukraińcy, Węgrzy, Amerykanie, Francuzi, Brytyjczycy, Białorusini, Rosjanie, Kozacy– na nich mogliśmy liczyć w 1920 roku." />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);

const Mobile = () => (
  <>
    <SEO title="Kto był sojusznikiem Polski w wojnie polsko-bolszewickiej | Bitwa Warszawska" lang="pl" description="Ukraińcy, Węgrzy, Amerykanie, Francuzi, Brytyjczycy, Białorusini, Rosjanie, Kozacy– na nich mogliśmy liczyć w 1920 roku." />
    <WrapperMobile />
  </>
);

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};

export default Page;
